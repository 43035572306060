import { useEffect, useState } from 'react'
import { useAxios } from '../useAxios.js'
import { useSearchParams } from 'react-router-dom';

import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import UserItem from '../components/UserItem.js';

function SearchPage() {
  const axios = useAxios();
  const [users, setUsers] = useState(null);
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q');

  useEffect(() => {
    if(axios && query) {
      axios.get('users', { params: { size: 999, q: query }}).then((response) => {
        setUsers(response.data.content || []);
      }).catch(error => {
        console.log("ERROR", error);
      });
    }
  }, [axios, query]);

  if(!users) {
    return <Grid container justifyContent="center" mt={4}><CircularProgress /></Grid>
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h5" mt={4} gutterBottom component="div">
        Showing results for <b>{query}</b>
      </Typography>
      <Divider />

      <Grid container spacing={2} mt={2} mb={4} alignItems="stretch">
        {users.map(u => (
          <Grid key={u.id} item lg={3} md={4} sm={6} xs={12}>
            <UserItem user={u} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default SearchPage;
