import Keycloak from 'keycloak-js'


// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak({
  realm: 'wizquant',
  url: 'https://app.wizquant.com/auth',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
})

export default keycloak
