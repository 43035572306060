import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef, Fragment } from "react";
import ReactQuill from 'react-quill';

import { Container } from '@mui/material';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { useAxios } from '../useAxios.js'


const tickers = ['APPL', 'MSF', 'IBM', 'TSLA', 'SPACE'];
const industries = ['Technology', 'Utilities', 'Consumer'];
const sectors = ['Computers', 'Biotechnology', 'Software'];

function InterestGrid({items, label, onChange, value}) {
  return (
    <Grid item xs={12}>
      <Autocomplete
        onChange={onChange}
        value={value}
        multiple disableClearable
        options={items}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
           <TextField {...params} variant="outlined" label={label} />
        )}
       />
    </Grid>
  );
}

function EditPostPage() {
  const axios = useAxios();
  const postId = useParams().postId;
  const navigate = useNavigate();
  const titleRef = useRef();
  const contentRef = useRef();

  const [formData, setFormData] = useState({
    title: "",
    content: '',
    draft: false,
    tickers: [],
    industries: [],
    sectors: [],
  });
  const [alerts, setAlerts] = useState({
    updated: false,
    created: false,
    errorMessage: false
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(!!postId);
  const [saving, setSaving] = useState(false);

  const [titleError, setTitleError] = useState(false);

  useEffect(() => {
    if(axios && postId) {
      axios.get(`posts/${postId}`).then((response) => {
        setFormData({
          title: response.data.title,
          content: response.data.content || '',
          draft:  response.data.draft,
          tickers:  response.data.tickers || [],
          industries:  response.data.industries || [],
          sectors:  response.data.sectors || [],
        });
      }).catch(e => {
        setError(e.message || 'Unknown error');
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [axios, postId]);

  function resetAlerts() {
    setAlerts({
      updated: false,
      created: false,
      errorMessage: false
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    if(validateTitle()) {
      titleRef.current.focus();
      return;
    }
    setSaving(true);

    const payload = Object.assign({}, formData, {content: contentRef.current.value});

    if (postId) {
      axios.put(`posts/${postId}`, payload).then((response) => {
        setAlerts({
          updated: true,
          created: false,
          errorMessage: false
        });
      }).catch(error => {
        console.log("ERROR", error);
        setAlerts({
          updated: false,
          created: false,
          errorMessage: error.toString()
        });
      }).finally(() =>{
        setSaving(false);
      });
    } else {
      axios.post('posts', payload).then((response) => {
        setAlerts({
          updated: false,
          created: true,
          errorMessage: false
        });
        navigate(`/posts/${response.data.id}/edit`);
      }).catch(error => {
        console.log("ERROR", error);
        setAlerts({
          updated: false,
          created: false,
          errorMessage: error.toString()
        });
      }).finally(() =>{
        setSaving(false);
      });
    }
  }

  function handleTitleChange(event) {
    const { value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      'title': value
    }));
    resetAlerts();
  }

  function validateTitle() {
    const error = !formData.title;
    setTitleError(error);
    return error;
  }

  function handleMultiselectChange(name, newValue) {
    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
    resetAlerts();
  }

  function handleCheckboxChange(event) {
    const {name, checked} = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
    resetAlerts();
  }

  function render() {
    if(error) {
      return <Alert severity="error">{error}</Alert>
    }

    if(loading) {
      return <Grid container justifyContent="center" mt={4}><CircularProgress /></Grid>
    }

    return (
      <Fragment>
        <Typography variant="h5" mt={4} gutterBottom component="div">
          { postId ? 'Edit post' : 'Create a new post'}
        </Typography>
        <Divider />

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} py={4}>
            <Grid item xs={12}>
              <FormControlLabel control={<Switch name="draft" checked={formData.draft} onChange={handleCheckboxChange} />} label="Draft" />
            </Grid>

            <Grid item xs={12}>
              <TextField fullWidth label="Title" variant="outlined" inputProps={{ maxLength: 255 }}
                inputRef={titleRef}
                error={titleError}
                value={formData.title}
                onChange={handleTitleChange}
                onBlur={validateTitle} />
            </Grid>
            <Grid item xs={12}>
              <Paper variant="outlined">
                <ReactQuill defaultValue={formData.content} ref={contentRef} theme="snow" className="wz-posts" />
              </Paper>
            </Grid>

            <InterestGrid
              items={tickers}
              label="Tickers"
              value={formData.tickers}
              onChange={(_, v) => {handleMultiselectChange('tickers', v)}} />
            <InterestGrid
              items={industries}
              label="Industries"
              value={formData.industries}
              onChange={(_, v) => {handleMultiselectChange('industries', v)}} />
            <InterestGrid
              items={sectors}
              label="Sectors"
              value={formData.sectors}
              onChange={(_, v) => {handleMultiselectChange('sectors', v)}} />

            { alerts.updated && (
              <Grid item xs={12}>
                <Alert severity="success">
                  Post successfully updated
                </Alert>
              </Grid>
            )}

            { alerts.created && (
              <Grid item xs={12}>
                <Alert severity="success">
                  Post successfully created
                </Alert>
              </Grid>
            )}

            { alerts.errorMessage && (
              <Grid item xs={12}>
                <Alert severity="error">{ alerts.errorMessage }</Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <LoadingButton type="submit" loading={saving} variant="contained" disableElevation>
              { formData.draft ? 'Save Draft' : 'Publish'}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Fragment>
    );
  }


  return (
    <Container maxWidth="md">
      <Box my={4}>
        { render() }
      </Box>
    </Container>
  );
}

export default EditPostPage;
