import { useState } from 'react'
import { Link as RouterLink } from "react-router-dom";
import { formatDistanceToNow } from 'date-fns'
import { useKeycloak } from '@react-keycloak/web'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActions } from '@mui/material';
import Collapse from '@mui/material/Collapse';

import UserAvatar from '../components/UserAvatar.js'
import PostComments from '../components/PostComments.js'


function Interest({items, label}) {
  if(!items) {
    return null;
  }
  return (
    <Grid item>
      <Typography variant="subtitle2" component="span">{ label }:</Typography>
      {items.map(c => (
        <Box ml={1} component="span" key={c}>
          <Chip label={c} variant="outlined" size="small" my={1} />
        </Box>
      ))}
    </Grid>
  );
}

function PostItem({post}) {
  const { keycloak } = useKeycloak();
  const [expanded, setExpanded] = useState(false);
  const [commentsPage, setCommentsPage] = useState();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid item>
      <Card variant="outlined">
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <UserAvatar userId={post.createdBy} avatarId={post.createdByAvatarId} name={post.createdByName} size={48} />
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <Typography variant="subtitle1">
                    <Link underline="hover" color="inherit" component={RouterLink} to={`/users/${post.createdBy}`}>
                      {post.createdByName}
                    </Link>
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary" component="div">
                    {formatDistanceToNow(new Date(post.createdDate))}
                  </Typography>
                </Grid>
                { !!post.previewImgSrc &&
                  <Grid item>
                    <CardMedia sx={{ display: { xs: 'block', sm: 'none' }, width: 56, height: 56 }} component="img" image={ post.previewImgSrc } />
                  </Grid>
                }
              </Grid>

              <Typography component="div" variant="h5" my={1}>
                <Link underline="hover" color="inherit" component={RouterLink} to={`/posts/${post.id}`}>{post.title}</Link>
              </Typography>

              <Typography component="div">
                { post.previewText }
              </Typography>
            </CardContent>

            { (post.tickers || post.industries || post.sectors) && (
              <Box sx={{ display: 'flex', alignItems: 'center', pb: 2, px: 2 }}>
                <Grid container direction="column" spacing={0.5}>
                  <Interest items={post.tickers} label="Tickers" />
                  <Interest items={post.industries} label="Industries" />
                  <Interest items={post.sectors} label="Sectors" />
                </Grid>
              </Box>
            )}
            <CardActions>
              <Button size="small" color="primary" expand={expanded.toString()} onClick={handleExpandClick} aria-expanded={expanded}>
                { commentsPage && commentsPage.totalElements ? commentsPage.totalElements : post.comments } Comments
              </Button>
              { keycloak.subject === post.createdBy &&
                <Button size="small" color="primary" component={RouterLink} to={`/posts/${post.id}/edit`}>
                  Edit
                </Button>
              }
            </CardActions>
          </Box>

          { !!post.previewImgSrc &&
            <CardMedia sx={{ display: { xs: 'none', sm: 'block' }, width: 256 }} component="img" image={ post.previewImgSrc } />
          }
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <PostComments postId={post.id} value={commentsPage} onChange={setCommentsPage} />
        </Collapse>
      </Card>
    </Grid>
  );
}

export default PostItem;
