import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

import { useAxios } from '../useAxios.js'
import UserAvatar from '../components/UserAvatar.js'


function CreateChatPage() {
  const navigate = useNavigate();
  const axios = useAxios();
  const [users, setUsers] = useState(null);
  const [checked, setChecked] = useState([]);
  const [saving, setSaving] = useState(false);
  const [savingError, setSavingError] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    if(axios) {
      axios.get('following', { params: { size: 999 }}).then((response) => {
        setUsers(response.data.content || []);
      }).catch(error => {
        console.log("ERROR", error);
      });
    }
  }, [axios]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      console.log('push', value)
      newChecked.push(value);
    } else {
      console.log('remove', currentIndex)
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSubmit = () => {
    setSaving(true);

    axios.post('chats', {
      name,
      userIds: checked,
    }).then((response) => {
      navigate(`/messages`);
    }).catch(error => {
      console.log("ERROR", error);
      setSavingError(error.toString());
    }).finally(() =>{
      setSaving(false);
    });
  }

  function NoFollowing() {
    return (
      <Container maxWidth="md" sx={{ mt:2 }}>
        <Typography variant="h5">You have no following</Typography>
      </Container>
    )
  }

  if(!users) {
    return <Grid container justifyContent="center" mt={4}><CircularProgress /></Grid>
  }

  if(users.length === 0) {
    return <NoFollowing />;
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h5" mt={4} gutterBottom component="div">
        New Chat
      </Typography>

      <Divider />

      <Grid container justifyContent="space-between" alignItems="center" my={2}>

        <TextField label="Chat title" variant="outlined" inputProps={{ maxLength: 255 }}
          value={name}
          onChange={ (e) => setName(e.target.value) } />

        <LoadingButton type="button" loading={saving} variant="contained" disableElevation onClick={handleSubmit}>
          Save
        </LoadingButton>
      </Grid>

      { savingError &&
        <Alert severity="error" sx={{mt:2}}>{ savingError }</Alert>
      }

      <Paper variant="outlined" sx={{ mt: 2 }}>

        <List>
          {users.map(u =>
            <ListItem disablePadding key={u.id}>
              <ListItemButton onClick={handleToggle(u.id)}>
                <ListItemAvatar>
                  <UserAvatar userId={u.id} avatarId={u.avatarId} name={u.name} withoutLink />
                </ListItemAvatar>
                <ListItemText primary={ u.name } />

                <ListItemIcon>
                  <Checkbox
                    edge="end"
                    checked={checked.indexOf(u.id) !== -1}
                    tabIndex={-1}
                    disableRipple />
                </ListItemIcon>

              </ListItemButton>
            </ListItem>
          )}
        </List>

      </Paper>
    </Container>
  );
}

export default CreateChatPage;
