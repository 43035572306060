import { useEffect } from "react"
import { Routes, Route, Navigate } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web'
import { StompSessionProvider } from "react-stomp-hooks";

import MainLayout from './layouts/MainLayout.js';
import ProfileLayout from './layouts/ProfileLayout.js';
import FeedPage from './pages/FeedPage.js';
import FollowersPage from './pages/FollowersPage.js';
import FollowingPage from './pages/FollowingPage.js';
import MessagesPage from './pages/MessagesPage.js';
import PostsPage from './pages/PostsPage.js';
import EditPostPage from './pages/EditPostPage.js';
import PostPage from './pages/PostPage.js';
import ProfilePage from './pages/ProfilePage.js';
import NotificationsPage from './pages/NotificationsPage.js';
import SearchPage from './pages/SearchPage.js';
import UserPage from './pages/UserPage.js';
import NotFoundPage from './pages/NotFoundPage.js';
import UserRoleRequired from './pages/UserRoleRequired.js';
import Loading from './components/Loading.js';
import CreateChatPage from './pages/CreateChatPage.js';

function AppRouting() {

  // always authenticated!
  const { keycloak } = useKeycloak()

  useEffect(() => {
     if (!keycloak.authenticated) {
       keycloak.login();
     }
  }, [keycloak]);

  if (keycloak.authenticated && keycloak.token) {
    if(keycloak.hasRealmRole('user')) {
      return (
        <StompSessionProvider url={process.env.REACT_APP_BASE + "api/stomp"}>
          <Routes>
            <Route element={<MainLayout />} >
              <Route index element={<Navigate to="feed" />} />
              <Route path="feed" element={<FeedPage />} />
              <Route path="followers" element={<FollowersPage />} />
              <Route path="following" element={<FollowingPage />} />
              <Route path="messages" element={<MessagesPage />} />
              <Route path="users/:userId/messages" element={<MessagesPage />} />
              <Route path="chats/new" element={<CreateChatPage />} />
              <Route path="chats/:chatId/messages" element={<MessagesPage />} />
              <Route path="search" element={<SearchPage />} />
              <Route path="posts" element={<PostsPage />} />
              <Route path="posts/new" element={<EditPostPage />} />
              <Route path="posts/:postId" element={<PostPage />} />
              <Route path="posts/:postId/edit" element={<EditPostPage />} />
              <Route path="users/:userId" element={<UserPage />} />
              <Route element={<ProfileLayout />} >
                <Route path="profile" element={<ProfilePage />} />
                <Route path="notifications" element={<NotificationsPage />} />
              </Route>
              <Route path="user-role-required" element={<Navigate to="feed" />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </StompSessionProvider>
      );
    } else if (keycloak.hasRealmRole('backtest')) {
      window.location.href = '/backtest';
      return null;
    } else {
      return (
        <Routes>
          <Route index element={<Navigate to="user-role-required" />} />
          <Route path="user-role-required" element={<UserRoleRequired />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      );
    }
  }
  return (<Loading />);
}

export default AppRouting;
