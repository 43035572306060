import { Link as RouterLink } from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { stringAvatar } from '../utils/AvatarUtils.js'

function AvatarIcon ({avatarId, name, userId, size, component, style}) {
  size = size || 40;
  if(!userId || (!avatarId && !name)) {
    return <AccountCircleIcon sx={{ fontSize: size }} />
  }
  if(avatarId) {
    return (
      <Avatar style={style}
        sx={{ width: size, height: size }}
        alt={name}
        src={process.env.REACT_APP_BASE + `api/users/${userId}/avatars/${avatarId}`} />
    );
  }
  return <Avatar style={style} {...stringAvatar(name, size, component)} />;
}

function UserAvatar({withoutLink, ...params}) {
  if(withoutLink)
    return <AvatarIcon {...params} />;
  return (<RouterLink to={`/users/${params.userId}`} style={{ textDecoration: 'none' }}>
    <AvatarIcon {...params} />
  </RouterLink>);
}

export default UserAvatar;
