import { useEffect, useState, useCallback, Fragment } from 'react'
import { useNavigate, useSearchParams } from "react-router-dom";
import throttle from 'lodash/throttle';

import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { useAxios } from '../useAxios.js'


const ALL = '__all__'

const SearchWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function Search() {
  const [searchParams] = useSearchParams();
  const initialQ = searchParams.get('q') || '';
  const axios = useAxios();
  const [query, setQuery] = useState(initialQ);
  const [inputValue, setInputValue] = useState(initialQ);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if(axios) {
      if(query) {
        setLoading(true);
        axios.get('users', { params: { size: 10, q: query }}).then((response) => {
          let options = response.data.content || [];
          if(response.data.totalElements > 0) {
            options.push({name: 'See all results', id: ALL});
          }
          setOptions(options);
        }).catch(error => {
          console.log("ERROR", error);
        }).finally(() => {
          setLoading(false);
        });
      } else {
        setOptions([]);
        setLoading(false);
      }
    }
  }, [axios, query]);

  function navigateToSearch(q) {
    const url = axios.getUri({ url: 'search', params: {q}});
    console.log('url', url);
    navigate(url);
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter' && inputValue) {
       // Prevent's default 'Enter' behavior.
       // event.defaultMuiPrevented = true;
       // your handler code
       navigateToSearch(inputValue);
    }
  }

  function handleClick(q) {
    console.log('handleClick', q);
    if(!q) {
      // do nothing
    } else if (q.id) {
      if(q.id === ALL) {
        navigateToSearch(inputValue);
      } else {
        navigate(`/users/${q.id}`);
      }
    } else {
      navigateToSearch(q);
    }
  }

  const throttleQuery = useCallback( // eslint-disable-line react-hooks/exhaustive-deps
    throttle(nextValue => setQuery(nextValue), 1000),
    [], // will be created only once initially
  );

  return (<Box sx={{flex: { xs: 1, sm: 0 }}}>
    <SearchWrapper>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
    <Autocomplete
      freeSolo disableClearable
      filterOptions={(x) => x}
      sx={{ width: { xs: '100%', sm: 300 }}}
      options={options}
      onInputChange={(a, v) => {
        if(v !== ALL) {
          setLoading(true);
          setInputValue(v);
          throttleQuery(v);
        }
      }}
      onKeyDown={handleKeyDown}
      inputValue={inputValue}
      loading={loading}
      isOptionEqualToValue={(option, value) => false}
      getOptionLabel={(option) => option.id === ALL ? ALL : (option.name || option)}

      renderOption={(props, option) => (
        <Box component="li"  {...props} onClick={(e,v) => {
          handleClick(option);
          props.onClick(e,v)
        }}>
          { option.name || option }
        </Box>
      )}

      renderInput={(params) => {
        const {InputLabelProps, InputProps, ...rest} = params;
        return (
          <StyledInputBase {...params.InputProps} {...rest}  placeholder="Search…" endAdornment={(
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} sx={{marginRight: 1}} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            )} />
      )}} />
    </SearchWrapper>
  </Box>);
}

export default Search;
