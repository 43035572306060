import { useEffect, useState } from 'react'
import { Link as RouterLink } from "react-router-dom";

import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { useAxios } from '../useAxios.js'
import PostItem from '../components/PostItem.js';


function PostsPage() {
  const axios = useAxios();
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    if(axios) {
      axios.get('users/me/posts', { params: { size: 999 }}).then((response) => {
        setPosts(response.data.content || []);
      }).catch(error => {
        console.log("ERROR", error);
      });
    }
  }, [axios]);

  return (
    <Container maxWidth="md">
      <Grid container justifyContent="flex-end" my={2}>
        <Button variant="contained" component={RouterLink} to="/posts/new" disableElevation>
          New Post
        </Button>
      </Grid>

      { !!posts
        ? (
          <Grid container direction="column" spacing={2} mb={4}>
            {posts.map(p => <PostItem key={p.id} post={ p } />)}
          </Grid>
        ) : (
          <Grid container justifyContent="center" mt={4}><CircularProgress /></Grid>
        )
      }
    </Container>
  );
}

export default PostsPage;
