import { useEffect, useState } from 'react'
import { Link as RouterLink } from "react-router-dom";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import DoneIcon from '@mui/icons-material/Done';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

import { useAxios } from '../useAxios.js'


function NotificationsPage() {
  const axios = useAxios();

  const [formData, setFormData] = useState({
    newFollowerWeb: false,
    newMessageWeb: false,
    newFollowerEmail: false,
    newMessageEmail: false,
  });
  const [notificationState, setNotificationState] = useState({
    newFollowerWeb: { progress: false, complete: false},
    newMessageWeb: { progress: false, complete: false},
    newFollowerEmail: { progress: false, complete: false},
    newMessageEmail: { progress: false, complete: false},
  });

  const [loading, setLoading] = useState(true);

  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    if(axios) {
      axios.get('users/me').then((response) => {
        setFormData({
          newFollowerWeb: response.data.newFollowerWeb,
          newMessageWeb: response.data.newMessageWeb,
          newFollowerEmail: response.data.newFollowerEmail,
          newMessageEmail: response.data.newMessageEmail,
        });
      }).catch(error => {
        console.log("ERROR", error);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [axios]);

  function handleChange(event) {
    const {name, checked} = event.target;
    console.log('handleChange', name, checked);

    setFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));

    setNotificationState(prevState => {
      let newState = {
        ...prevState,
        [name]: {progress: true, complete: false}
      };
      for(let k in newState) {
        newState[k].complete = false;
      }
      return newState;
    });

    axios.patch('users/me', {[name]: checked}).then((response) => {
      console.log('saved');

      setNotificationState(prevState => ({
        ...prevState,
        [name]: {progress: false, complete: true}
      }));

      setErrorMessage(false);
    }).catch(error => {
      console.log("ERROR", error);

      setNotificationState(prevState => ({
        ...prevState,
        [name]: {progress: false, complete: false}
      }));

      setErrorMessage(error.toString());
    });
  };

  function CheckboxCell({name}) {
    return (
      <TableCell sx={{ minWidth: 72, paddingLeft: '8px' }}>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Checkbox checked={formData[name]} onChange={handleChange} name={name} disabled={loading} />
          </Grid>
          { notificationState[name].progress && (
            <Grid item>
              <CircularProgress size={18} />
            </Grid>
          )}
          { notificationState[name].complete && (
            <Grid item>
              <DoneIcon color="success" />
            </Grid>
          )}
        </Grid>
      </TableCell>
    );
  }

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" pb={2}>
        <Link underline="hover" color="inherit" component={RouterLink} to="/profile">
          Settings
        </Link>
        <Link underline="hover" color="inherit" component={RouterLink} to="/notifications">
          Notifications
        </Link>
      </Breadcrumbs>

      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Web</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>New followers</TableCell>
              <CheckboxCell name="newFollowerWeb" />
              <CheckboxCell name="newFollowerEmail" />
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>New messages</TableCell>
              <CheckboxCell name="newMessageWeb" />
              <CheckboxCell name="newMessageEmail" />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      { errorMessage && (
        <Box my={2}>
          <Alert severity="error">{ errorMessage }</Alert>
        </Box>
      )}

    </div>
  );
}

export default NotificationsPage;
