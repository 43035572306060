import { useNavigate } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

function NotFoundPage() {
  const navigate = useNavigate();

  function handleHome() {
    navigate('/');
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}>

      <Grid item>
         <Typography variant="h1">404</Typography>
         <Grid container justifyContent="center">
           <Button variant="outlined" onClick={handleHome}>Go to Home</Button>
         </Grid>
      </Grid>

    </Grid>
  );
}

export default NotFoundPage;
