import { useEffect, useState } from 'react'
import { useAxios } from '../useAxios.js'

import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import UserItem from '../components/UserItem.js';

function FollowersPage() {
  const axios = useAxios();
  const [users, setUsers] = useState(null);

  useEffect(() => {
    if(axios) {
      axios.get('followers', { params: { size: 999 }}).then((response) => {
        setUsers(response.data.content || []);
      }).catch(error => {
        console.log("ERROR", error);
      });
    }
  }, [axios]);

  if(!users) {
    return <Grid container justifyContent="center" mt={4}><CircularProgress /></Grid>
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h5" mt={4} gutterBottom component="div">
        Followers
      </Typography>
      <Divider />

      <Grid container spacing={2} mt={2} mb={4} alignItems="stretch">
        {users.map(u => (
          <Grid key={u.id} item lg={3} md={4} sm={6} xs={12}>
            <UserItem user={u} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default FollowersPage;
