import { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

function LinkTab(props) {
  return (
    <Tab
      component={ Link }
      to={ props['value'] }
      {...props}
    />
  );
}

const tabs = [
  {label: "Feed", value: "/feed"},
  {label: "My Posts", value: "/posts"},
  {label: "Messages", value: "/messages"},
  {label: "Followers", value: "/followers"},
  {label: "Following", value: "/following"},
];

function SocialMenu() {
  const location = useLocation();
  let tabValue = tabs.map(i => i.value).indexOf(location.pathname) === -1 ? false : location.pathname;
  if(!tabValue) {
    if(location.pathname.indexOf('/posts') === 0
        && (location.pathname.indexOf('/edit') !== -1 || location.pathname.indexOf('/new') !== 1)) {
      tabValue = '/posts';
    } else if(location.pathname.indexOf('/messages') !== -1 || location.pathname.indexOf('/chats/new') !== -1)
      tabValue = "/messages";
  }
  const [value, setValue] = useState(tabValue);

  const theme = useTheme();
  const horizontal = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(tabValue)
  }, [tabValue]);

  return (
    <Container maxWidth="md">
      {/* variant="scrollable" allowScrollButtonsMobile */}
      <Tabs value={value} onChange={handleChange} orientation={horizontal ? "horizontal" : "vertical"}>
        {tabs.map(props => <LinkTab key={props.value} {...props} />)}
      </Tabs>
    </Container>
  );
}

export default SocialMenu;
