import { Outlet, Link, useLocation } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web'

import { Container } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const menuItems = [
  {label: "Profile", value: "/profile"},
  {label: "Notifications", value: "/notifications"},
];

function ProfileLayout() {
  let location = useLocation();
  const { keycloak } = useKeycloak()

  return (
    <Container maxWidth="md">
      <Grid container spacing={4} my={2}>
        <Grid item sm={4} xs={12}>
          <Paper variant="outlined">

            <List component="nav" aria-label="main mailbox folders">
              {menuItems.map(props =>
                <ListItem disablePadding key={props.value}>
                  <ListItemButton
                    component={ Link } to={ props.value }
                    selected={props.value === location.pathname}>
                    <ListItemText primary={ props.label } />
                  </ListItemButton>
                </ListItem>
              )}

              <ListItem disablePadding>
                <ListItemButton
                  component="a" href={ keycloak.createAccountUrl() }
                  selected={ false }>
                  <ListItemText primary="Security" />
                </ListItemButton>
              </ListItem>
            </List>

          </Paper>
        </Grid>
        <Grid item sm={8} xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ProfileLayout;
