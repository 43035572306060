import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

function Loading() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}>
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
}

export default Loading;
