import { useState } from 'react'
import { Link as RouterLink } from "react-router-dom";

import Link from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';

import { useAxios } from '../useAxios.js'
import UserAvatar from '../components/UserAvatar.js'


function Interest({items, label}) {
  if(!items) {
    return null;
  }
  return (
    <Grid item>
      <Typography variant="subtitle2" component="span">{ label }:</Typography>
      {items.map(c => (
        <Box ml={1} component="span" key={c}>
          <Chip label={c} variant="outlined" size="small" my={1} />
        </Box>
      ))}
    </Grid>
  );
}

const TRANDING_EXPERIENCE_LABES = {
  SELF_EDUCATED: 'Self-educated',
  PROFESSIONAL: 'Professional'
};

const AVATAR_SIZE = 128;

function UserItem(params) {
  const axios = useAxios();
  const [user, setUser] = useState(params.user);

  function handleFollow(user) {
    axios.post(`users/${user.id}/follow`).then((response) => {
      setUser(Object.assign({}, user, {follow: true}));
    }).catch(error => {
      console.log("ERROR", error);
    });
  }

  function handleUnfollow(user) {
    axios.delete(`users/${user.id}/follow`).then((response) => {
      setUser(Object.assign({}, user, {follow: false}));
    }).catch(error => {
      console.log("ERROR", error);
    });
  }

  function toSubheader(user) {
    if(!user.jobPosition && !user.companyName)
      return false;
    if(user.jobPosition && user.companyName)
      return user.jobPosition + ' at ' + user.companyName;
    if(user.jobPosition)
      return user.jobPosition;
    return  user.companyName;
  }

  const cardStyle = Object.assign({}, {height: "100%"}, params.style)

  return (
    <Card variant="outlined" style={ cardStyle }>
      <Grid container direction="column" justifyContent="space-between" style={{height: "100%"}} pt={2} pb={1}>
        <Grid container item alignItems="center" direction="column">
          <Grid item>
            <UserAvatar userId={user.id} avatarId={user.avatarId} name={user.name} size={AVATAR_SIZE} component='h3' />
          </Grid>

          <Grid item pt={2}>
            <Typography variant="h5">
              <Link underline="hover" color="inherit" component={RouterLink} to={`/users/${user.id}`}>{user.name}</Link>
            </Typography>
          </Grid>

          <Grid item>
            <Typography color="text.secondary">
              {toSubheader(user)}
            </Typography>
          </Grid>

          { user.tradingExperience && (
            <Grid item>
              <Typography variant="body2" color="text.secondary">
                Trading experience: {TRANDING_EXPERIENCE_LABES[user.tradingExperience]}
              </Typography>
            </Grid>
          )}

          { (user.interestTickers || user.interestIndustries || user.interestSectors) && (
            <Grid item>
              <Box mt={1}>
                <Grid container direction="column" spacing={0.5}>
                  <Interest items={user.interestTickers} label="Tickers" />
                  <Interest items={user.interestIndustries} label="Industries" />
                  <Interest items={user.interestSectors} label="Sectors" />
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>

        <Grid container item alignItems="center" mt={2} direction="column">
          <Grid item>
            { user.follow ? (
              <Button startIcon={<ClearIcon />} onClick={()=> handleUnfollow(user)}>
                Unfollow
              </Button>
            ) : (
              <Button startIcon={<PersonAddIcon />} onClick={() => handleFollow(user)}>
                Follow
              </Button>
            )}
            <Button component={ RouterLink } to={`/users/${user.id}/messages`}>
              Send message
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default UserItem;
