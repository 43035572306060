import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { ReactKeycloakProvider } from '@react-keycloak/web'

import keycloak from './keycloak'
import AppRouting from './AppRouting.js';
import Loading from './components/Loading.js';

import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import './components/Quill.sass'


function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<Loading />}
      initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}>
        <AppRouting />
    </ReactKeycloakProvider>
  );
}

export default App;
