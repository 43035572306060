import { useState, useEffect, useRef } from 'react'
import ReactQuill from 'react-quill';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';

import { useAxios } from '../useAxios.js'
import PostCommentItem from '../components/PostCommentItem.js'


function PostComments({postId, value, onChange}) {
  const axios = useAxios();
  const contentRef = useRef();
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if(axios) {
      axios.get(`/posts/${postId}/comments`, { params: { size: 999 }}).then((response) => {
        if(onChange) {
          onChange(response.data);
        }
      }).catch(error => {
        console.log("ERROR", error);
      });
    }
  }, [axios, postId, onChange]);

  function getComments(){
    axios.get(`/posts/${postId}/comments`, { params: { size: 999 }}).then((response) => {
      if(onChange) {
        onChange(response.data);
      }
    }).catch(error => {
      console.log("ERROR", error);
    });
  }

  function handleSend() {
    setSending(true);
    axios.post(`/posts/${postId}/comments`, {
      content: contentRef.current.value
    }).then((response) => {
      contentRef.current.editor.setText('');
      getComments();
    }).catch(error => {
      console.log("ERROR", error);
    }).finally(() => {
      setSending(false);
    });
  }

  if(!value)
    return <Grid container justifyContent="center" my={3}><CircularProgress /></Grid>

  return (
    <Grid container p={1} spacing={2}>

      <Grid item xs={12}>
        <Paper variant="outlined">
          <ReactQuill ref={contentRef} theme="snow" />
        </Paper>
        <Grid container justifyContent="flex-end" mt={1}>
          <LoadingButton loading={sending} variant="contained" disableElevation endIcon={<SendIcon />} onClick={handleSend}>
            Send
          </LoadingButton>
        </Grid>
      </Grid>

      {!!value.content && value.content.map(c =>
        <Grid item xs={12} key={c.id} mx={2}>
          <PostCommentItem postId={postId} comment={c} />
        </Grid>
      )}

    </Grid>
  );
}

export default PostComments;
