import { useKeycloak } from '@react-keycloak/web'

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';


function UserRoleRequired() {
  const { keycloak } = useKeycloak()

  function logout() {
    keycloak.logout();
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}>

      <Grid item>
         <Typography variant="h5">
           Please contact to Admin to activate account
         </Typography>
         <Grid container justifyContent="center">
           <Button variant="outlined" onClick={logout}>Logout</Button>
         </Grid>
      </Grid>

    </Grid>
  )
}

export default UserRoleRequired;
