import { useState, useEffect, useCallback } from 'react'
import { Link } from "react-router-dom";
import { useSubscription } from "react-stomp-hooks";
import { useKeycloak } from '@react-keycloak/web'

import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useAxios } from '../useAxios.js'


function Notifications() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const axios = useAxios();
  const [notifications, setNotifications] = useState([]);
  const { keycloak } = useKeycloak();

  useSubscription(`/topic/users/${keycloak.subject}/notifications`, (message) => {
    console.log('got ws notifications', message.body);
    getNotifications();
  });

  const toogleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification) => {
    axios.delete(`notifications/${notification.groupKey}`).then((response) => {
      setNotifications(notifications.filter(n => n.groupKey !== notification.groupKey));
    }).catch(error => {
      console.log("ERROR", error);
    });
    setAnchorEl(null);
  };

  const getNotifications = useCallback(() => {
    axios.get(`notifications`, { params: { size: 999 }}).then((response) => {
      setNotifications(response.data.content || []);
    }).catch(error => {
      console.log("ERROR", error);
    });
  }, [axios]);

  useEffect(() => {
    if(axios) {
      getNotifications();
    }
  }, [axios, getNotifications]);

  function formatNotification(n) {
    switch (n.type) {
      case 'MESSAGE':
        return `New message from ${n.name}`;
      case 'FOLLOW':
        return `${n.name} follow you`;
      case 'UNFOLLOW':
        return `${n.name} unfollow you`;
      default:
        break;
    }
    return 'Unknown notification type';
  }

  return (<div>
    <IconButton size="large" color="inherit"
      id="notifications-basic-button"
      aria-controls={open ? 'notifications-basic-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      onClick={toogleMenu}>
      <Badge badgeContent={notifications.length} color="error">
        <NotificationsIcon />
      </Badge>
    </IconButton>
    { notifications.length > 0 &&
      <Menu
        id="notifications-basic-menu"
        MenuListProps={{
          'aria-labelledby': 'notifications-basic-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {notifications.map(n => (
          <MenuItem key={n.groupKey} onClick={() => handleNotificationClick(n)} component={Link}
            to={ n.type === 'MESSAGE' ? '/messages' : '/followers' }>
            { formatNotification(n) }
          </MenuItem>
        ))}

      </Menu>
    }
  </div>);
}

export default Notifications;
