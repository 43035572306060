import { useParams, Link as RouterLink } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";
import { formatDistanceToNow } from 'date-fns'
import ReactQuill from 'react-quill';
import { useKeycloak } from '@react-keycloak/web'

import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';
import Alert from '@mui/material/Alert';

import { useAxios } from '../useAxios.js'
import UserAvatar from '../components/UserAvatar.js'
import PostComments from '../components/PostComments.js'

function PostPage() {
  const { keycloak } = useKeycloak();
  const axios = useAxios();
  const postId = useParams().postId;

  const [post, setPost] = useState();
  const [createdBy, setCreatedBy] = useState();
  const [loading, setLoading] = useState(true);
  const [commentsPage, setCommentsPage] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    if(axios && postId) {
      axios.get(`posts/${postId}`).then((response) => {
        setPost(response.data);

        axios.get(`users/${response.data.createdBy}`).then((response) => {
          setCreatedBy(response.data);
        }).catch(e => {
          setError(e.message || 'Unknown error');
        });

      }).catch(e => {
        setError(e.message || 'Unknown error');
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [axios, postId]);

  function render() {
    if(error) {
      return <Alert severity="error">{error}</Alert>
    }

    if(loading) {
      return <Grid container justifyContent="center"><CircularProgress /></Grid>
    }

    return (
      <Fragment>
        <Grid container spacing={1} mb={2}>
          <Grid item>
            <UserAvatar userId={post.createdBy} avatarId={createdBy?.avatarId} name={createdBy?.name} />
          </Grid>
          <Grid item>
            <Typography>
              <Link underline="hover" color="inherit" component={RouterLink} to={`/users/${post.createdBy}`}>{ createdBy?.name }&nbsp;</Link>
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              { formatDistanceToNow(new Date(post.createdDate), { addSuffix: true }) }
            </Typography>
          </Grid>

          <div style={{flex: 1}} />

          { keycloak.subject === post.createdBy &&
            <Grid item>
              <Button size="small" color="primary" component={RouterLink} to={`/posts/${post.id}/edit`}>
                Edit
              </Button>
            </Grid>
          }
        </Grid>

        <Typography variant="h5" gutterBottom component="div">
          { post.title }
        </Typography>

        <Divider />
        <Box mt={2} mb={4}>
          <ReactQuill defaultValue={post.content} theme="bubble" className="wz-posts" />
        </Box>

        <Typography variant="h5" gutterBottom component="div">
          Comments
          { commentsPage && ' (' + commentsPage.totalElements + ')' }
        </Typography>
        <Divider />
        <PostComments postId={postId} value={commentsPage} onChange={setCommentsPage} />
      </Fragment>
    )
  }



  return (
    <Container maxWidth="md">
      <Box my={4}>
        { render() }
      </Box>
    </Container>
  );
}

export default PostPage;
