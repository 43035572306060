import { useEffect, useState, useMemo } from 'react'
import { useSearchParams } from "react-router-dom";

import { Container } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { useAxios } from '../useAxios'
import PostItem from '../components/PostItem';


const tickers = ['APPL', 'MSF', 'IBM', 'TSLA', 'SPACE'];
const industries = ['Technology', 'Utilities', 'Consumer'];
const sectors = ['Computers', 'Biotechnology', 'Software'];

function InterestGrid({items, label, onChange, value}) {
  return (
    <Grid item xs={12}>
      <Autocomplete
        onChange={onChange}
        value={value}
        multiple disableClearable
        options={items}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
           <TextField {...params} variant="standard" label={label} />
        )}
       />
    </Grid>
  );
}


function FeedPage() {
  const axios = useAxios();
  const [searchParams, setSearchParams] = useSearchParams();
  const [posts, setPosts] = useState(null);

  const filter = useMemo(() => {
    return {
      type: searchParams.get('type') || 'MY_INTERESTS',
      ticker: searchParams.getAll('ticker'),
      industry: searchParams.getAll('industry'),
      sector: searchParams.getAll('sector'),
    }
  }, [searchParams]);

  useEffect(() => {
    if(axios) {
      const parseParams = (params) => {
        const keys = Object.keys(params);
        let options = '';

        keys.forEach((key) => {
          const isParamTypeObject = typeof params[key] === 'object';
          const isParamTypeArray = isParamTypeObject && (params[key].length >= 0);

          if (!isParamTypeObject) {
            options += `${key}=${params[key]}&`;
          }

          if (isParamTypeObject && isParamTypeArray) {
            params[key].forEach((element) => {
              options += `${key}=${element}&`;
            });
          }
        });

        return options ? options.slice(0, -1) : options;
      };

      axios.get('posts', {
        params: Object.assign({}, filter, {size: 999}),
        paramsSerializer: params => parseParams(params)
      }).then((response) => {
        setPosts(response.data.content || []);
      }).catch(error => {
        console.log("ERROR", error);
      });
    }
  }, [axios, filter]);

  function handleFilterChange(event) {
    const {value} = event.target;
    if( value === 'CUSTOM') {
      setSearchParams({
         ...filter,
         type: value
      });
    } else {
      setSearchParams({
        type: value
      });
    }
  }

  function handleMultiselectChange(name, newValue) {
    setSearchParams({
       ...filter,
       [name]: newValue
    });
  }

  if(!posts) {
    return <Grid container justifyContent="center" mt={4}><CircularProgress /></Grid>
  }

  return (
    <Container maxWidth="md">
      <Grid container justifyContent="flex-end" my={2}>
        <Grid item>
          <ToggleButtonGroup
              color="primary"
              value={filter.type}
              exclusive
              onChange={handleFilterChange}>
            <ToggleButton value="MY_INTERESTS">My interests</ToggleButton>
            <ToggleButton value="CUSTOM">Custom</ToggleButton>
            <ToggleButton value="ALL">All</ToggleButton>
          </ToggleButtonGroup>

          { /*
            <FormControl fullWidth variant="standard">
              <InputLabel id="post-filter-select-label">Filter</InputLabel>
              <Select labelId="post-filter-select-label" label="Filter" value={filter.type} onChange={handleFilterChange}>
                <MenuItem value="MY_INTERESTS">My interests</MenuItem>
                <MenuItem value="CUSTOM">Custom</MenuItem>
                <MenuItem value="ALL">All</MenuItem>
              </Select>
            </FormControl>
          */ }
        </Grid>
      </Grid>

      { filter.type === 'CUSTOM' &&
        <Grid container spacing={1} mb={4}>
          <InterestGrid
            items={tickers}
            label="Tickers"
            value={filter.ticker}
            onChange={(_, v) => {handleMultiselectChange('ticker', v)}} />
          <InterestGrid
            items={industries}
            label="Industries"
            value={filter.industry}
            onChange={(_, v) => {handleMultiselectChange('industry', v)}} />
          <InterestGrid
            items={sectors}
            label="Sectors"
            value={filter.sector}
            onChange={(_, v) => {handleMultiselectChange('sector', v)}} />
        </Grid>
      }

      <Grid container direction="column" spacing={2} mb={4}>
        {posts.map(p => <PostItem key={p.id} post={ p } />)}
      </Grid>

    </Container>
  );
}

export default FeedPage;
