import { Outlet, useLocation } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import ServicesMenu from '../components/ServicesMenu.js'
import ProfileMenu from '../components/ProfileMenu.js'
import SocialMenu from '../components/SocialMenu.js'
import Search from '../components/Search.js'
import Notifications from '../components/Notifications.js'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


function MainLayout() {
  const location = useLocation();
  const isMessagesPage = location.pathname.indexOf('/users/') !== -1
                && location.pathname.indexOf('/messages') !== -1;
  const theme = useTheme();
  const showHeader = useMediaQuery(theme.breakpoints.up('sm')) || !isMessagesPage;

  const headerStyles = isMessagesPage ? {
     position: 'fixed',
     width: '100%',
     top: 0,
     background: '#fff',
     zIndex: 1
  } : {};
  return (
    <Box sx={{ paddingTop: { xs: 0, sm: isMessagesPage ? '145px' : 0 }}}>

      { showHeader && (
        <Box style={ headerStyles }>
          <AppBar position="static" elevation={0}>
            <Toolbar>
              <Search />
              <Box sx={{ flex: { xs: 0, sm: 1 } }} />
              <Notifications />
              <ServicesMenu />
              <ProfileMenu />
            </Toolbar>
          </AppBar>

          <Box mt={4}>
            <SocialMenu />
            <Divider />
          </Box>
        </Box>
      )}

      <Outlet />
    </Box>
  );
}

export default MainLayout;
