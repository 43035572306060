import { useEffect, useState, useRef, useCallback } from 'react'
import { useParams, Link as RouterLink } from "react-router-dom";
import { useSubscription } from "react-stomp-hooks";
import { useKeycloak } from '@react-keycloak/web'
import { formatDistanceToNow } from 'date-fns'

import ReactQuill from 'react-quill';

import { Container } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';

import { useAxios } from '../useAxios.js'
import UserAvatar from '../components/UserAvatar.js'

function MessageItem({message, label, avatar}) {
  return (
    <Grid item>
      <Grid container spacing={1}>
        <Grid item>{ avatar }</Grid>
        <Grid item>
          <Typography>{ label }</Typography>
          <Typography variant="subtitle2" color="text.secondary">
            { formatDistanceToNow(new Date(message.createdDate), { addSuffix: true }) }
          </Typography>
        </Grid>
      </Grid>
      <ReactQuill theme="bubble" defaultValue={ message.content } readOnly={true} />
    </Grid>
  );
}

function NoMessage() {
  return (
    <Container maxWidth="md">
      <Typography variant="h5">You have no message yet</Typography>
    </Container>
  )
}

function MessagesPage() {
  const axios = useAxios();
  const {userId, chatId} = useParams();
  const newMessageRef = useRef()

  const [threads, setThreads] = useState(null);
  const [messages, setMessages] = useState(null);
  const [chatMessages, setChatMessages] = useState(null);
  const [user, setUser] = useState(null);
  const [me, setMe] = useState(null);
  const [sending, setSending] = useState(false);

  const { keycloak } = useKeycloak();

  // console.log('subscribe', `/topic/users/${keycloak.subject}/messages`);
  useSubscription(`/topic/users/${keycloak.subject}/messages`, (message) => {
    console.log('got WS messages', message.body);
    if(userId) {
      getMessages();
    }
    if(chatId) {
      getChatMessages();
    }
    getThreads();
  });

  const getThreads = useCallback(() => {
    axios.get('message_threads').then((response) => {
      setThreads(response.data.content || []);
    }).catch(error => {
      console.log("ERROR", error);
    });
  }, [axios]);

  const getMessages = useCallback(() => {
    axios.get(`users/${userId}/messages`, { params: { size: 999 }}).then((response) => {
      let messages = response.data.content || [];
      setMessages(messages.reverse());
    }).catch(error => {
      console.log("ERROR", error);
    });
  }, [axios, userId]);

  const getChatMessages = useCallback(() => {
    axios.get(`chats/${chatId}/messages`, { params: { size: 999 }}).then((response) => {
      let messages = response.data.content || [];
      setChatMessages(messages.reverse());
    }).catch(error => {
      console.log("ERROR", error);
    });
  }, [axios, chatId]);

  useEffect(() => {
    if(axios) {
      getThreads();
    }
  }, [axios, getThreads]);

  const messageScrollRef = useRef();

  useEffect(() => {
    if(messages || chatMessages) {
      if(messageScrollRef.current)
        messageScrollRef.current.scrollIntoView();
      // setTimeout(() => {
      //   if(newMessageRef.current)
      //     newMessageRef.current.editor.focus();
      // });
    }
  }, [messages, chatMessages]);

  useEffect(() => {
    if(axios && userId) {
      getMessages();
      setUser(null);

      axios.get(`users/${userId}`).then((response) => {
        setUser(Object.assign(response.data, {id: userId}));
      }).catch(error => {
        console.log("ERROR", error);
      });
    }
  }, [axios, userId, getMessages]);

  useEffect(() => {
    if(axios && userId) {
      if (!me) {
        axios.get('users/me').then((response) => {
          setMe(response.data);
        }).catch(error => {
          console.log("ERROR", error);
        });
      }
    }
  }, [axios, me, userId]);

  useEffect(() => {
    if(axios && chatId) {
      getChatMessages();
    }
  }, [axios, chatId, getChatMessages]);

  function handleSend() {
    setSending(true);
    axios.post(`users/${userId}/messages`, {
      content: newMessageRef.current.value
    }).then((response) => {
      console.log('success');
      newMessageRef.current.editor.setText('');
    }).catch(error => {
      console.log("ERROR", error);
    }).finally(() => {
      setSending(false);
    });
  }

  const handleChatSend = () => {
    setSending(true);
    axios.post(`chats/${chatId}/messages`, {
      content: newMessageRef.current.value
    }).then((response) => {
      console.log('success');
      newMessageRef.current.editor.setText('');
    }).catch(error => {
      console.log("ERROR", error);
    }).finally(() => {
      setSending(false);
    });
  }

  function Messages() {
    return (
      <Container maxWidth="md">
        <Grid container justifyContent="flex-end" my={2}>
          <Button variant="contained" disableElevation component={ RouterLink } to={ `/chats/new`}>
            New Group Chat
          </Button>
        </Grid>
        <Box my={2}>
          <Paper variant="outlined">

            <List component="nav">
              {threads.map(t =>
                <ListItem disablePadding key={t.chatId || t.userId}>
                  <ListItemButton selected={false}
                    component={ RouterLink } to={ t.chatId ? `/chats/${t.chatId}/messages` : `/users/${t.userId}/messages` }>
                    <ListItemAvatar>
                      <UserAvatar userId={t.userId} avatarId={t.avatarId} name={t.name} withoutLink />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Grid container direction="row" justifyContent="space-between">
                          <Grid item>{ t.chatName || t.name }</Grid>
                          <Grid item>
                            <Typography variant="subtitle2" color="text.secondary">
                              { formatDistanceToNow(new Date(t.createdDate), { addSuffix: true }) }
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                      secondary={t.messagePreview} />
                  </ListItemButton>
                </ListItem>
              )}
            </List>

          </Paper>
        </Box>
      </Container>
    );
  }

  function LeftUsersMenu() {
    return (
      <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' }}}>
        { threads.length > 0 &&
          <Paper variant="outlined" style={{ position: 'fixed', top: '177px', width: '262px'}}>

            <List component="nav">
              {threads.map(t =>
                <ListItem disablePadding key={t.chatId || t.userId}>
                  <ListItemButton selected={(userId && t.userId === userId && !t.chatId) || (chatId && t.chatId === chatId)}
                    component={ RouterLink } to={ t.chatId ? `/chats/${t.chatId}/messages` : `/users/${t.userId}/messages` }>
                    <ListItemAvatar>
                      <UserAvatar userId={t.userId} avatarId={t.avatarId} name={t.name} withoutLink />
                    </ListItemAvatar>
                    <ListItemText primary={ t.name } secondary={t.chatName} />
                  </ListItemButton>
                </ListItem>
              )}
            </List>

          </Paper>
        }
      </Grid>
    );
  }

  function UserMessages() {
    return (
      <Container maxWidth="md">
        <Box my={4}>
          <Grid container spacing={4}>

            <LeftUsersMenu />

            <Grid item sm={8} xs={12}>
              <Grid container direction="column" spacing={4} mb={{xs: 2, sm: '156px'}}>
                {messages.map(m => m.toUserId === keycloak.subject
                    ? <MessageItem key={m.id}
                        message={m}
                        label={!!user && <Link underline="hover" color="inherit" component={RouterLink} to={`/users/${user.id}`}>{user.name}</Link>}
                        avatar={<UserAvatar userId={user?.id} avatarId={user?.avatarId} name={user?.name} />} />
                    : <MessageItem key={m.id}
                        message={m}
                        label={ 'You' }
                        avatar={<UserAvatar userId={me.id} avatarId={me.avatarId} name={me.name} />} />
                )}
                <div ref={messageScrollRef} />
              </Grid>

              <Box pb={2}
                style={{ background: '#fff' }}
                sx={{ position: { sm: 'fixed' }, bottom: {sm: 0}, maxWidth: {sm: 'calc((900px / 12) * 8)'}, width: {sm: '100%'}}}>
                <Paper variant="outlined">
                  <ReactQuill theme="snow" ref={newMessageRef} />
                </Paper>
                <Grid container justifyContent="space-between" mt={1}>
                  <Button component={ RouterLink } to={`/messages`} startIcon={<ArrowBackIcon />} sx={{ display: { md: 'none' }}}>
                    Back
                  </Button>
                  <div style={{flex: 1}} />
                  <LoadingButton loading={sending} variant="contained" disableElevation endIcon={<SendIcon />} onClick={handleSend}>
                    Send
                  </LoadingButton>
                </Grid>
              </Box>

            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }

  function ChatMessages() {
    return (
      <Container maxWidth="md">
        <Box my={4}>
          <Grid container spacing={4}>

            <LeftUsersMenu />

            <Grid item sm={8} xs={12}>
              <Grid container direction="column" spacing={4} mb={{xs: 2, sm: '156px'}}>
                {chatMessages.map(m => m.userId !== keycloak.subject
                    ? <MessageItem key={m.id}
                        message={m}
                        label={<Link underline="hover" color="inherit" component={RouterLink} to={`/users/${m.userId}`}>{m.name}</Link>}
                        avatar={<UserAvatar userId={m.userId} avatarId={m.avatarId} name={m.name} />} />
                    : <MessageItem key={m.id}
                        message={m}
                        label={ 'You' }
                        avatar={<UserAvatar userId={m.userId} avatarId={m.avatarId} name={m.name} />} />
                )}
                <div ref={messageScrollRef} />
              </Grid>

              <Box pb={2}
                style={{ background: '#fff' }}
                sx={{ position: { sm: 'fixed' }, bottom: {sm: 0}, maxWidth: {sm: 'calc((900px / 12) * 8)'}, width: {sm: '100%'}}}>
                <Paper variant="outlined">
                  <ReactQuill theme="snow" ref={newMessageRef} />
                </Paper>
                <Grid container justifyContent="space-between" mt={1}>
                  <Button component={ RouterLink } to={`/messages`} startIcon={<ArrowBackIcon />} sx={{ display: { md: 'none' }}}>
                    Back
                  </Button>
                  <div style={{flex: 1}} />
                  <LoadingButton loading={sending} variant="contained" disableElevation endIcon={<SendIcon />} onClick={handleChatSend}>
                    Send
                  </LoadingButton>
                </Grid>
              </Box>

            </Grid>
          </Grid>
        </Box>
      </Container>
    );
  }

  if(!threads || (userId && (!messages || !me)) || (chatId && !chatMessages)) {
    return <Grid container justifyContent="center" mt={4}><CircularProgress /></Grid>
  }

  if(!userId && threads && threads.length === 0) {
    return <NoMessage />
  }

  if(userId) {
    return <UserMessages />
  }

  if(chatId) {
    return <ChatMessages />
  }

  return <Messages />
}

export default MessagesPage;
