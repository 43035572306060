import { useState, useRef, Fragment } from 'react'
import ReactQuill from 'react-quill';
import { Link as RouterLink } from "react-router-dom";
import { formatDistanceToNow } from 'date-fns'

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';

import { useAxios } from '../useAxios.js'
import UserAvatar from '../components/UserAvatar.js'


function PostCommentItem({comment, postId, onChange}) {
  const axios = useAxios();
  const contentRef = useRef();
  const [sending, setSending] = useState(false);
  const [replyVisible, setReplyVisible] = useState(false);
  const [repliesVisible, setRepliesVisible] = useState(false);
  const [replies, setReplies] = useState();

  function handleSend() {
    setSending(true);
    axios.post(`/posts/${postId}/comments`, {
      content: contentRef.current.value,
      parentId: comment.id,
    }).then((response) => {
      contentRef.current.editor.setText('');
      setReplyVisible(false);
      setRepliesVisible(true);
      getComments();
    }).catch(error => {
      console.log("ERROR", error);
    }).finally(() => {
      setSending(false);
    });
  }

  function handleReplyClick() {
    setReplyVisible(true);
  }

  function handleRepliesClick() {
    if(repliesVisible) {
      setRepliesVisible(false);
      setReplies(null);
    } else {
      setRepliesVisible(true);
      getComments();
    }
  }

  function getComments(){
    axios.get(`/posts/${postId}/comments/${comment.id}/comments`, { params: { size: 999 }}).then((response) => {
      Object.assign(comment, {comments: response.data.totalElements});
      setReplies(response.data);
    }).catch(error => {
      console.log("ERROR", error);
    });
  }

  function renderReplies() {
    if(!replies)
      return <Grid container justifyContent="center" my={3}><CircularProgress /></Grid>
    return (
      <Fragment>
        {replies.content.map(c =>
          <Grid item xs={12} key={c.id} mx={2}>
            <PostCommentItem postId={postId} comment={c} />
          </Grid>
        )}
      </Fragment>
    );
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item mt={1}>
        <UserAvatar userId={comment.createdBy} avatarId={comment.createdByAvatarId} name={comment.createdByName} size={32} />
      </Grid>
      <Grid item style={{flex: 1}}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <Link underline="hover" color="inherit" component={RouterLink} to={`/users/${comment.createdBy}`}>
                {comment.createdByName}
              </Link>
            </Typography>
            <ReactQuill theme="bubble" defaultValue={ comment.content } readOnly={true} />
            <Typography variant="subtitle2" color="text.secondary" component="span">
              {formatDistanceToNow(new Date(comment.createdDate), { addSuffix: true })}
            </Typography>
            <Button variant="text" size="small" onClick={handleReplyClick}>Reply</Button>
            { !!comment.comments &&
              <Button variant="text" size="small" onClick={handleRepliesClick}>{comment.comments} Replies</Button>
            }
          </Grid>
          { replyVisible && (
            <Grid item xs={12}>
              <Paper variant="outlined">
                <ReactQuill ref={contentRef} theme="snow" />
              </Paper>
              <Grid container justifyContent="flex-end" mt={1}>
                <LoadingButton loading={sending} variant="contained" disableElevation endIcon={<SendIcon />} onClick={handleSend}>
                  Send
                </LoadingButton>
              </Grid>
            </Grid>
          )}
          { repliesVisible && renderReplies() }
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PostCommentItem;
