import { useEffect, useState } from 'react'
import { Link as RouterLink } from "react-router-dom";
import { format, subYears } from 'date-fns'

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker'
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import { useAxios } from '../useAxios.js'
import { eventBus } from "../utils/EventBus";
import UserAvatar from '../components/UserAvatar.js'


const tickers = ['APPL', 'MSF', 'IBM', 'TSLA', 'SPACE'];
const industries = ['Technology', 'Utilities', 'Consumer'];
const sectors = ['Computers', 'Biotechnology', 'Software'];


function InterestGrid({items, label, onChange, value}) {
  return (
    <Grid item xs={12}>
      <Autocomplete
        onChange={onChange}
        value={value}
        multiple disableClearable
        options={items}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
           <TextField {...params} variant="standard" label={label} />
        )}
       />
    </Grid>
  );
}

function ProfilePage() {
  const axios = useAxios();
  const [formData, setFormData] = useState({
    name: "",
    birthday: null,
    address: "",
    city: "",
    country: "",
    phone: "",
    companyName: "",
    jobPosition: "",
    tradingExperience: "",
    interestTickers: [],
    interestIndustries: [],
    interestSectors: [],
  });
  const [alerts, setAlerts] = useState({
    updated: false,
    errorMessage: false
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [avatarId, setAvatarId] = useState();
  const [userId, setUserId] = useState();

  useEffect(() => {
    if(axios) {
      axios.get('users/me').then((response) => {
        setFormData({
          name: response.data.name || '',
          birthday: response.data.birthday || null,
          address: response.data.address || '',
          city: response.data.city || '',
          country: response.data.country || '',
          phone: response.data.phone || '',
          companyName: response.data.companyName || '',
          jobPosition: response.data.jobPosition || '',
          tradingExperience: response.data.tradingExperience || '',
          interestTickers: response.data.interestTickers || [],
          interestIndustries: response.data.interestIndustries || [],
          interestSectors: response.data.interestSectors || [],
        });
        setAvatarId(response.data.avatarId);
        setUserId(response.data.id);
      }).catch(error => {
        console.log("ERROR", error);
        setAlerts({
          updated: false,
          errorMessage: error.toString()
        });
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [axios]);

  function handleSubmit(e) {
    e.preventDefault();
    const payload = Object.fromEntries(Object.entries(formData).filter(([_, v]) => v !== ''))
    setSaving(true);
    resetAlerts();
    axios.put('users/me', payload).then((response) => {
      setAlerts({
        updated: true,
        errorMessage: false
      });
    }).catch(error => {
      console.log("ERROR", error);
      setAlerts({
        updated: false,
        errorMessage: error.toString()
      });
    }).finally(() =>{
      setSaving(false);
    });
  }

  function resetAlerts() {
    setAlerts({
      updated: false,
      errorMessage: false
    });
  }

  function handleInputChange(event) {
    const {name, value} = event.target;

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    resetAlerts();
  }

  function handleBirthdayChange(newValue){
    const newBirthday = isNaN(newValue.getTime()) ? null : format(newValue, 'yyyy-MM-dd')
    setFormData(prevState => ({
      ...prevState,
      'birthday': newBirthday
    }));
    resetAlerts();
  }

  function handleMultiselectChange(name, newValue) {
    setFormData(prevState => ({
      ...prevState,
      [name]: newValue
    }));
    resetAlerts();
  }

  function handleUpload(e) {
    let fd = new FormData();
    for (let f of e.target.files) {
      console.log(f)
      fd.append('file', f);
    }

    axios.post('/users/me/avatars', fd, {
      headers: {'Content-Type': 'multipart/form-data'}
    }).then(response => {
      console.log(response);
      setAvatarId(response.data.id);
      eventBus.emit('onAvatarIdChanged', response.data.id);
    }).catch(error => {
      console.log(error);
    });
  }

  return (<div>
        <Breadcrumbs aria-label="breadcrumb" pb={2}>
          <Link underline="hover" color="inherit" component={RouterLink} to="/profile">
            Settings
          </Link>
          <Link underline="hover" color="inherit" component={RouterLink} to="/profile">
            Edit Profile
          </Link>
        </Breadcrumbs>

        <Divider />

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} py={4}>
            <Grid item sm={6} xs={12}>
              <Button component="label" fullWidth>
                  <UserAvatar userId={userId} avatarId={avatarId} name={formData.name} size={128} component='h3' withoutLink />
                  <input type="file" hidden onChange={handleUpload} />
              </Button>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12}>
                  <TextField fullWidth label="Name" name="name" value={formData.name} onChange={handleInputChange} variant="standard" />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="standard">
                     <InputLabel id="trading-experience-select-label">Trading experience</InputLabel>
                     <Select
                       labelId="trading-experience-select-label"
                       value={formData.tradingExperience}
                       label="Trading experience"
                       name="tradingExperience"
                       onChange={handleInputChange}>
                       <MenuItem value="">None</MenuItem>
                       <MenuItem value="SELF_EDUCATED">Self-educated</MenuItem>
                       <MenuItem value="PROFESSIONAL">Professional</MenuItem>
                     </Select>
                   </FormControl>
                </Grid>
              </Grid>
            </Grid>


            <Grid item sm={6} xs={12}>
              <TextField fullWidth label="Company name" name="companyName" value={formData.companyName} onChange={handleInputChange} variant="standard" />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField fullWidth label="Job position" name="jobPosition" value={formData.jobPosition} onChange={handleInputChange} variant="standard" />
            </Grid>

          </Grid>

          <Divider textAlign="right">Interests</Divider>

          <Grid container spacing={2} py={4}>
            <InterestGrid
              items={tickers}
              label="Tickers"
              value={formData.interestTickers}
              onChange={(_, v) => {handleMultiselectChange('interestTickers', v)}} />
            <InterestGrid
              items={industries}
              label="Industries"
              value={formData.interestIndustries}
              onChange={(_, v) => {handleMultiselectChange('interestIndustries', v)}} />
            <InterestGrid
              items={sectors}
              label="Sectors"
              value={formData.interestSectors}
              onChange={(_, v) => {handleMultiselectChange('interestSectors', v)}} />
          </Grid>

          <Divider textAlign="right">Private information</Divider>

          <Grid container spacing={2} py={4}>
            <Grid item sm={6} xs={12}>
              <TextField fullWidth type="tel" label="Phone" name="phone" value={formData.phone} onChange={handleInputChange} variant="standard" />
            </Grid>
            <Grid item sm={6} xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Birthday"
                  defaultCalendarMonth={subYears(new Date(), 30)}
                  value={formData.birthday}
                  onChange={handleBirthdayChange}
                  renderInput={(params) => <TextField fullWidth variant="standard" {...params} />}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <TextField fullWidth label="Address" name="address" value={formData.address} onChange={handleInputChange} variant="standard" />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField fullWidth label="City" name="city" value={formData.city} onChange={handleInputChange} variant="standard" />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField fullWidth label="Country" name="country" value={formData.country} onChange={handleInputChange} variant="standard" />
            </Grid>
          </Grid>

          <Grid container spacing={2} pb={4}>
            { alerts.updated && (
              <Grid item xs={12}>
                <Alert severity="success">Profile successfully updated</Alert>
              </Grid>
            )}

            { alerts.errorMessage && (
              <Grid item xs={12}>
                <Alert severity="error">{ alerts.errorMessage }</Alert>
              </Grid>
            )}

            <Grid item xs={12}>
              <LoadingButton type="submit" loading={saving} disabled={loading} variant="contained" disableElevation>Save</LoadingButton>
            </Grid>

          </Grid>

        </form>
  </div>);
}

export default ProfilePage;
