import { useState, useEffect } from 'react'
import { useAxios } from '../useAxios.js'

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppsIcon from '@mui/icons-material/Apps';

function ServicesMenu() {
  const axios = useAxios();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [dashboards, setDashboards] = useState([]);

  const toogleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (axios) {
      axios.get(`${process.env.REACT_APP_DASHBOARDS_BASE_URL}/dashboards`, { params: { topMenuOnly: true } }).then((response) => {
        setDashboards(response.data.dashboards || [])
      }).catch(error => {
        console.log("ERROR", error);
      });
    }
  }, [axios]);

  return (
    <div>
      <IconButton
        id="services-basic-button"
        color="inherit"
        size="large"
        aria-controls={open ? 'services-basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={toogleMenu}>
        <AppsIcon />
      </IconButton>
      <Menu
        id="services-basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'services-basic-button',
        }}>
        <MenuItem onClick={handleClose} component="a" href="https://app.wizquant.com/dashboard">Widgets</MenuItem>
        <MenuItem onClick={handleClose} component="a" href="https://app.wizquant.com/backtest">Backtest</MenuItem>
        <MenuItem onClick={handleClose} component="a" href="https://app.wizquant.com/indicators/tickers">Screener</MenuItem>
        <MenuItem onClick={handleClose} component="a" href="https://app.wizquant.com/autodetect/single">Chart Patterns</MenuItem>
        { dashboards.map(d => {
          const url = `https://app.wizquant.com/dashboard/dashboards/${d.id}`
          return <MenuItem onClick={handleClose} component="a" href={url} key={d.id}>{d.name}</MenuItem>;
        })}
      </Menu>
    </div>
  );
}

export default ServicesMenu;
