import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";

import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import { useAxios } from '../useAxios.js'
import PostItem from '../components/PostItem.js';
import UserItem from '../components/UserItem.js';


function UserPage() {
  const axios = useAxios();
  const userId = useParams().userId;
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    if(axios) {
      axios.get(`users/${userId}`).then((response) => {
        setUser(Object.assign(response.data, {id: userId}));
      }).catch(error => {
        console.log("ERROR", error);
      });

      axios.get(`users/${userId}/posts`, { params: { size: 999 }}).then((response) => {
        setPosts(response.data.content || []);
      }).catch(error => {
        console.log("ERROR", error);
      });
    }
  }, [axios, userId]);

  if(!user || !posts) {
    return <Grid container justifyContent="center" mt={4}><CircularProgress /></Grid>;
  }

  return (
    <Container maxWidth="lg">

      <Grid container alignItems="flex-start" spacing={1} pt={2}>
        <Grid item md={4} xs={12}>
          <UserItem user={user} style={{ border: "none" }} />
        </Grid>

        <Grid item md={8} xs={12}>
          <Grid container direction="column" spacing={2} mb={4}>
            {posts.map(p => <PostItem key={p.id} post={ p } />)}
          </Grid>
        </Grid>
      </Grid>

    </Container>
  );
}

export default UserPage;
