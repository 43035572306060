import { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { useKeycloak } from '@react-keycloak/web'

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useAxios } from '../useAxios.js'
import { eventBus } from "../utils/EventBus";
import UserAvatar from '../components/UserAvatar.js'


function ProfileMenu() {
  const axios = useAxios();
  const { keycloak } = useKeycloak()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [user, setUser] = useState();

  useEffect(() => {
    if(axios) {
      axios.get('users/me').then((response) => {
        setUser(response.data);
      }).catch(error => {
        console.log("ERROR", error);
      });
    }
  }, [axios]);

  useEffect(() => {
    eventBus.on('onAvatarIdChanged', (avatarId) => {
      setUser(prevState => ({
        ...prevState,
        avatarId
      }));
    })

    return () => {
      eventBus.off('onAvatarIdChanged')
    }
  }, [])

  function logout() {
    keycloak.logout();
    setAnchorEl(null);
  }

  const toogleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton color="inherit" size="large" onClick={toogleMenu}>
        <UserAvatar userId={user?.id} avatarId={user?.avatarId} name={user?.name} size={35} withoutLink />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose} component={Link} to="/profile">Profile Settings</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

export default ProfileMenu;
