
import Typography from '@mui/material/Typography';

function stringToColor(string) {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

export function stringAvatar(name, size, variant) {
  const splitted = name.split(' ');
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: size,
      height: size
    },
    children: <Typography variant={variant}>{splitted.length === 2 ? `${splitted[0][0]}${splitted[1][0]}` : splitted[0][0]}</Typography>,
  };
}
